import React, { ReactElement } from 'react'

import { Container } from './styles'

export default function NotFoundPage() : ReactElement {
	return (
		<Container>
			
		</Container>
	)
}